import { APIEntity } from "./api.entity";
import { Resource, deserializeString } from "@patterns/core";

@Resource('/companies')
export class Company extends APIEntity {
  code: string
  name: string
  email: string

  constructor(data: any = {}) {
    super(data);
    this.code = deserializeString(data.code);
    this.name = deserializeString(data.name);
    this.email = deserializeString(data.email);
  }
}
