import { Button, Flex, Item, Meter, Picker, Text, View } from '@adobe/react-spectrum';
import Contrast from '@spectrum-icons/workflow/Contrast';
import Logout from '@spectrum-icons/workflow/LogOut';
import * as React from 'react';
import { ColorScheme } from '../pages/main.page';
import { AddTemplateDialog } from './template.dialog';
import { Template } from '../entities';
import { AppContext } from '../app_context';
import { ApplicationsDialog } from './applications.dialog';

export interface Props {
  onColorSchemeChange: (scheme: ColorScheme) => void
  onTemplateSelect: (template: Template) => void
  onTemplatesChanged: () => void
  selected: Template
  templates: Template[]
}

export function Navbar({ onColorSchemeChange, onTemplateSelect, onTemplatesChanged, selected, templates }: Props) {
  const context = React.useContext(AppContext);
  const [nightMode, setNightMode] = React.useState(context.scheme === 'dark');

  React.useEffect(() => {
    onColorSchemeChange(nightMode ? 'dark' : 'light')
  }, [nightMode, onColorSchemeChange]);

  const onTemplateSelection = (key: React.ReactText) => {
    const template = templates.find(template => template.id === key.toString());
    if (template) {
      onTemplateSelect(template)
    }
  }

  return (
    <View id="navbar">
      <View id="navbar-inner" backgroundColor="gray-50" borderBottomColor="gray-300" borderBottomWidth="thin">
        <Flex alignItems="center" direction="row" height="50px">
          <p className="spectrum-Body spectrum-Body--large" style={{ width: 276 }}>RENDERER</p>
          <Flex alignItems="center" direction='row' flex="1">
            <Picker 
              selectedKey={selected.id} 
              label="Template" 
              labelPosition="side" 
              isQuiet={true}
              items={templates}
              onSelectionChange={onTemplateSelection}>
              { template => <Item key={template.id}>{template.name}</Item> }
            </Picker>
            <AddTemplateDialog afterCreate={onTemplatesChanged}/>
            <ApplicationsDialog afterCreate={onTemplatesChanged}/>
          </Flex>
          <Flex alignItems="center" justifyContent="end" direction='row' flex="1">
            <Meter label="Storage" variant="positive" value={35} />
            <Button variant="primary" marginStart="12px" isQuiet={true} onPress={() => setNightMode(!nightMode)}>
              <Contrast/>
            </Button>
            <Button variant="primary" marginStart="12px" onPress={context.session.destroy}>
              <Logout/>
              <Text>Logout</Text>
            </Button>
          </Flex>
        </Flex>
      </View>
    </View>
  );
}
