import { APIEntity } from "./api.entity";
import { Resource, deserializeString, deserializeNumber } from "@patterns/core";
import { Template } from "./template.entity";
import { Company } from "./company.entity";
import { Application } from "./application.entity";

export type RenderRequestOptionsCSV = {
  fieldSeparator: string,
  textDelimiter: string,
  characterSet: string
}

export type RenderRequestOptionsPDF = {
  AllowDuplicateFieldNames?: boolean
  CenterWindow?: boolean
  Changes?: number
  ConvertOOoTargetToPDFTarget?: boolean
  DisplayPDFDocumentTitle?: boolean
  DocumentOpenPassword?: string
  EnableCopyingOfContent?: boolean
  EnableTextAccessForAccessibilityTools?: boolean
  EncryptFile?: boolean
  ExportBookmarks?: boolean
  ExportBookmarksToPDFDestination?: boolean
  ExportFormFields?: boolean
  ExportLinksRelativeFsys?: boolean
  ExportNotes?: boolean
  ExportNotesPages?: boolean
  FirstPageOnLeft?: boolean
  FormsType?: number
  HideViewerMenubar?: boolean
  HideViewerToolbar?: boolean
  hideViewerWindowControls?: boolean
  InitialPage?: number
  InitialView?: number
  IsAddStream?: boolean
  IsSkipEmptyPages?: boolean
  Magnification?: number
  MaxImageResolution?: number
  OpenBookmarkLevels?: number
  OpenInFullScreenMode?: boolean
  PageLayout?: number
  PageRange?: string
  PDFViewSelection?: number
  PermissionPassword?: string
  Printing?: number
  Quality?: number
  ReduceImageResolution?: boolean
  ResizeWindowToInitialPage?: boolean
  RestrictPermissionPassword?: boolean
  SelectPdfVersion?: number
  UseLosslessCompression?: boolean
  UseTaggedPDF?: boolean
  UseTransitionEffects?: boolean
  Watermark?: string
  Zoom?: number
}

export type RenderFormat = 'pdf' | 'odt' | 'ods' | 'xlsx' | 'csv';

export type RenderRequestFormatOptions = {
  formatName: RenderFormat,
  formatOptions: RenderRequestOptionsPDF | RenderRequestOptionsCSV
}

export type RenderRequestOptions = {
  convertTo: RenderFormat | RenderRequestOptions
}

export type RenderRequest = {
  data: { [id:string]: any }
  options?: RenderRequestOptions
}

export enum RenderState {
  Created = 'created',
  Failed = 'failed',
  Rendered = 'rendered'
}

export enum RenderReturnType {
  Document = 'document',
  URL = 'url'
}

@Resource('/renders')
export class Render extends APIEntity {
  data: RenderRequest
  format: RenderFormat
  hash: string
  code: string;
  returnType: RenderReturnType
  error?: string
  regeneration: boolean
  state: RenderState
  template: Template
  application: Application
  templateVersion: number
  views: number
  company: Company

  constructor(data: any = {}) {
    super(data);
    this.data = data.data || {};
    this.format = deserializeString(data.format) as RenderFormat;
    this.hash = deserializeString(data.hash);
    this.code = deserializeString(data.code);
    this.regeneration = typeof data.regeneration === 'undefined' ? false : data.regeneration;
    this.returnType = deserializeString(data.returnType) as RenderReturnType;
    this.state = deserializeString(data.state) as RenderState;
    this.template = new Template(data.template || {});
    this.application = new Application(data.application || {});
    this.templateVersion = deserializeNumber(data.templateVersion, 0);
    this.views = deserializeNumber(data.views, 0);
    this.company = new Company(data.company || {});
  }
}
