import * as React from 'react';
import { Button, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Flex, Form, Heading, Text, TextField, RadioGroup, Radio } from '@adobe/react-spectrum';
import AddCircle from '@spectrum-icons/workflow/AddCircle';
import { Template } from '../entities';
import { TemplateRepository } from '../repositories/template.repository';

export interface Props {
  afterCreate: () => void
}

export function AddTemplateDialog({ afterCreate }: Props) {
  const [ name, setName ] = React.useState('');
  const [ code, setCode ] = React.useState('');
  const [ format, setFormat ] = React.useState('odt');
  const [ showDialog, setShowDialog ] = React.useState(false);
  
  React.useEffect(() => {
    const newCode = name.toLowerCase().replace(/ /g, '-').replace(/\./g, '-');
    setCode(newCode);
  }, [ name ]);

  const save = async () => {
    const template = new Template({
      code,
      name,
      format
    });
    await TemplateRepository.save(template);
    setShowDialog(false);
    afterCreate()
  }

  return (
    <DialogTrigger isOpen={showDialog} onOpenChange={setShowDialog}>
      <Button variant="cta" marginStart="24px">
        <AddCircle />
        <Text>Add Template</Text>
      </Button>
      
      {(close) => (
        <Dialog size="S">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <AddCircle size="S" />
              <Text>Add new Template</Text>
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <Form>
              <TextField
                label="Name"
                placeholder="Enter template name"
                value={name}
                autoFocus
                onChange={setName}
              />
              <TextField
                label="Code (used in URL)"
                placeholder="Enter template code"
                value={code} 
                onChange={setCode}
              />
              <RadioGroup label="Format" orientation="horizontal" value={format} onChange={setFormat}>
                <Radio value="odt">ODT</Radio>
                <Radio value="ods">ODS</Radio>
                <Radio value="odt">XLSX</Radio>
              </RadioGroup>
            </Form>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button variant="cta" onPress={save}>
              Create
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
}
