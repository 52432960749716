import { DialogTrigger, Heading, Divider, Content, Button, AlertDialog } from '@adobe/react-spectrum';
import Magnify from '@spectrum-icons/workflow/Magnify';
import * as React from 'react';
import { Application } from '../entities';

export interface Props {
  application: Application
}

export default function TokenPopover ({ application }: Props) {
  const copy = () => {
    navigator.clipboard.writeText(application.token);
  }

  return (
    <DialogTrigger type="popover">
      <Button marginTop="5px" marginStart="12px" variant="secondary" isQuiet={true}><Magnify /></Button>
      <AlertDialog title="Application Token" variant="destructive" primaryActionLabel="Copy to clipboard" cancelLabel="Cancel" onPrimaryAction={copy}>
        <Heading>{ application.name } Access Token</Heading>
        <Divider />
        <Content>
          <p className="token-preview">{ application.token }</p>
        </Content>
      </AlertDialog>
    </DialogTrigger>
  );
}
