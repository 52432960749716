import { Template } from "../entities";
import { session } from '../App';

export class TemplateRepository {
  static async index(page: number = 0, perPage: number = 100, cancelToken: any) {
    const response = await session.axios.get(`/templates`);
    const items = response.data.items.map((item: any) => new Template(item)) as Template[];
    const total = response.data.count as number;
    return [ items, total ]
  }

  static async save(template: Template) {
   if (template.exists) {
    await session.axios.put(`/templates/${template.id}`, template)
   } else {
    await session.axios.post('/templates', template)
   }

   return
  }
}