import { deserializeString, deserializeDate, deserializeNumber } from '@patterns/core';

export class APIEntity {
  id: string
  createdAt: Date
  updatedAt: Date
  version: number

  constructor(data: any = {}) {
    this.id = deserializeString(data.id);
    this.createdAt = deserializeDate(data.createdAt);
    this.updatedAt = deserializeDate(data.updatedAt);
    this.version = deserializeNumber(data.version, 0);
  }

  get exists() {
    return typeof this.id === 'string' && this.id.length > 0
  }
}
