import * as React from 'react';
import { Provider, defaultTheme, Flex, View, Dialog, Text, Heading, Divider, Content, ButtonGroup, Button, DialogTrigger, Form, TextField } from '@adobe/react-spectrum';
import { User } from '../entities';
import { Session } from '@patterns/session';
import { session } from '../App';

export type ValidationState = 'valid' | 'invalid' | undefined;

export interface Props {
  session: Session<User>
}

export interface State {
  email: string
  password: string
}

export class LoginPage extends React.Component<Props, State> {
  state = {
    email: '',
    password: ''
  }

  private setEmail = (email: string) => this.setState({ email })
  
  private setPassword = (password: string) => this.setState({  password })

  private login = async () => {
    try {
      await session.login(this.state.email, this.state.password);
    } catch (e) {
      console.error('session error', e)
    }
  }

  public render() {
    return <Provider theme={defaultTheme} colorScheme="light">
      <div id="login-content-bg"></div>
      <View 
        id="login-content" 
        flex="1"
        height="100vh">
        <Flex direction="row">
          <View flex="1">left</View>
          <View flex="1">right</View>
        </Flex>
      </View>
      
      <DialogTrigger isOpen={true}>
        <span/>
        <Dialog size="S">
          <Heading>
            RENDERER
          </Heading>
          <Divider />
          <Content >
            <View height="36px">
              <Text>Please enter your credentials</Text>
            </View>
            <Form>
              <TextField
                label="E-Mail"
                placeholder="Enter your e-mail"
                value={this.state.email}
                autoFocus
                onChange={this.setEmail}
                type="email"
              />
              <TextField
                label="Password"
                placeholder="Enter your password"
                value={this.state.password} 
                onChange={this.setPassword}
                type="password"
                minLength={8}
              />
            </Form>
          </Content>
          <ButtonGroup>
            <Button variant="cta" onPress={this.login} autoFocus>
              Continue
            </Button>
          </ButtonGroup>
        </Dialog>
      </DialogTrigger>
    </Provider>
  }
}
