import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Axios from 'axios';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { environment }  from './common';

Sentry.init({
  dsn: "https://187e85f3f208407b8a4ee952879dbd4d@o449457.ingest.sentry.io/5434424",
  environment,
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

Axios.defaults.baseURL = '/api';

ReactDOM.render(
  <App />,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
