import { Render, Template } from "../entities";
import { session } from '../App';

export class RenderRepository {
  static async index(page: number = 0, perPage: number = 100, template: Template, cancelToken: any) {
    const url = `/renders?template_id=${template.id}`;
    const response = await session.axios.get(url);
    const items = response.data.items.map((item: any) => new Render(item)) as Render[];
    const total = response.data.count as number;
    return [ items, total ]
  }

  static async save(template: Render) {
   if (template.exists) {
    await session.axios.put(`/renders/${template.id}`, template)
   } else {
    await session.axios.post('/renders', template)
   }

   return
  }
}