import * as React from 'react';
import { View, Button, Divider, Flex, TextField, Text, IllustratedMessage, Heading, Content, Form, ProgressCircle } from '@adobe/react-spectrum';
import Dropzone from 'react-dropzone';
import Upload from '@spectrum-icons/workflow/UploadToCloud';
import { Template } from '../entities';
import { formatDate } from '@patterns/core';
import { TemplateRepository } from '../repositories/template.repository';
import { AppContext } from '../app_context';

const ACCEPTED = ['application/vnd.oasis.opendocument.text', 'application/vnd.oasis.opendocument.spreadsheet', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'].join(', ');

export interface Props {
  selected: Template
  onChange: () => void
}

export function Sidebar({ selected, onChange }: Props) {
  const context = React.useContext(AppContext);
  const [ name, setName ] = React.useState(selected.name);
  const [ code, setCode ] = React.useState(selected.code);
  const [ uploading, setUploading ] = React.useState(false);
  const [ replace, setReplace ] = React.useState(false);
  
  React.useEffect(() => {
    setName(selected.name);
    setCode(selected.code);
    setReplace(false);
  }, [ selected ]);
 
  if (!selected.exists) {
    return <span/>
  }

  const onSubmit = (evt: React.FormEvent<Element>) => {
    evt.preventDefault();
  }

  const onDrop = async (files: File[]) => {
    var formData = new FormData();
    const file = files[0];
    formData.append("name", file.name);
    formData.append("file", file);
    setUploading(true);
    await context.session.axios.post(`/templates/${ selected.id }/upload`, formData, {
      headers: {
        'Authorization': context.session.axios.defaults.headers.common['Authorization'],
        'Content-Type': 'multipart/form-data'
      }
    })
    setUploading(false);
    setReplace(false);
    onChange()
  }

  const save = async () => {
    const template = new Template(selected);
    template.name = name;
    await TemplateRepository.save(template);
    setReplace(false)
    onChange()
  }

  return (
    <View backgroundColor="gray-100" id="sidebar" width="300px">
      <div style={{ margin: 24 }}>
        <Flex direction="column">
          <Form onSubmit={onSubmit}>
            <TextField label="Template Name" value={name} onChange={setName} labelPosition="top" isQuiet={true} width="100%"/>
            <TextField label="Template Code" isDisabled={true} value={code} onChange={setCode} labelPosition="top" isQuiet={true} width="100%" marginTop="12px"/>

            <Divider size="M" marginTop="12px"/>

            <Button variant="primary" onPress={save}>
              <Text>Save changes</Text>
            </Button> 

            <Divider size="M" />

            { uploading ? <IllustratedMessage>
              <Heading>
                <ProgressCircle aria-label="Loading…" isIndeterminate size="L" marginTop="17px"/>
                <br/>
                <Text>UPLOADING</Text>
              </Heading>
              <Content>
                Please wait
              </Content>
            </IllustratedMessage> : <span/> }

            { !uploading  ? <View>
              <Flex direction="row">
                <Flex direction="column" justifyContent="start">
                  <Text width="90px">Template File</Text>
                  <Text>{ selected.original }</Text>
                </Flex>
                <Flex justifyContent="start" flex="1" direction="column" alignItems="end">
                  { (!replace && selected.file) ? <Button onPress={() => setReplace(true)} variant="primary">Replace</Button> : <span/>}
                </Flex>
              </Flex>
              { (!selected.file || replace) && <Dropzone onDrop={onDrop}>
                {({getRootProps, getInputProps}) => (
                  <IllustratedMessage marginTop="12px">
                    <div  {...getRootProps()}>
                    <input {...getInputProps()} accept={ACCEPTED}/>
                    <Upload />
                    <Heading>Drag and Drop Your Template</Heading>
                    <Content>
                      Select an <strong>{ selected.format }</strong> File from your computer
                    </Content>
                    </div>
                  </IllustratedMessage>
                )}
              </Dropzone> }
            </View> : <span/>}
          </Form>

          <Divider size="M" marginTop="12px" marginBottom="12px"/>

          <Flex direction="column">
            <Text flex="1">Render URL (POST)</Text>
            <Flex flex="1">
              <Text>{ `/api/renders/${selected.code}` }{ '.{format}'}</Text>
            </Flex>
          </Flex>

          <Divider size="M" marginTop="12px" marginBottom="12px"/>

          <Flex direction="row">
            <Text flex="1">Created At</Text>
            <Flex justifyContent="end" flex="1">
            <Text>{ formatDate(selected.createdAt) }</Text>
            </Flex>
          </Flex>

          <Flex direction="row">
            <Text flex="1">Current Version</Text>
            <Flex justifyContent="end" flex="1">
              <Text>{ selected.version }</Text>
            </Flex>
          </Flex>

          <Divider size="M" marginTop="12px" marginBottom="12px"/>

          <Flex direction="row">
            <Text flex="1">Times used</Text>
            <Flex justifyContent="end" flex="1">
              <Text>{ selected.counter }</Text>
            </Flex>
          </Flex>

          <Flex direction="row">
            <Text flex="1">Regenerations</Text>
            <Flex justifyContent="end" flex="1">
              <Text>{ selected.regenerations }</Text>
            </Flex>
          </Flex>

          <Divider size="M" marginTop="12px" marginBottom="12px"/>

          <Button variant="negative">
            <Text>Delete Template</Text>
          </Button> 
        </Flex>
      </div>
    </View>
  );
}
