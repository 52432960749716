import * as React from 'react';
import { Session } from '@patterns/session';
import produce from 'immer';

import { Navbar } from '../components/navbar';
import { Flex, View, Provider, defaultTheme } from '@adobe/react-spectrum';
import { Sidebar } from '../components/sidebar';
import { User, Template } from '../entities';
import { session } from '../App';
import { TemplateRepository } from '../repositories/template.repository';
import { Renders } from '../components/renders';
import { AppContextValue, AppContext } from '../app_context';

export type ColorScheme = 'light' | 'dark';

export interface Props {
  session: Session<User>
}

export interface State {
  contextValue: AppContextValue
  selected: Template
  templates: Template[]
}

export class MainPage extends React.Component<Props> {
  static contextType = AppContext;

  cancelToken: any

  state = {
    contextValue: {
      scheme: 'light' as ColorScheme,
      session
    },
    selected: new Template({}),
    templates: []
  }

  componentDidMount() {
    this.fetch(true)
  }

  private fetch = async (selectLatest: boolean = false) => {
    const [ templates ] = await TemplateRepository.index(0, 100, this.cancelToken);
    const state = { templates: templates as Template[] } as State
    if (selectLatest) {
      if (state.templates.length > 0) {
        const sorted = state.templates.sort((a, b) => b.updatedAt.getTime() - a.updatedAt.getTime());
        state.selected = sorted[0];
      }
    }
    this.setState(state)
  }

  private onModeChange = (scheme: ColorScheme) => this.setState({ 
    contextValue: produce(this.state.contextValue, context => {
      context.scheme = scheme
    })
  })

  private onTemplateSelect = (template: Template) => this.setState({ selected: template })

  private onTemplatesChanged = () => this.fetch(true)

  public render() {
    console.log('background', this.context.scheme === 'light' ? 'gray-300' : 'static-black');
    return <AppContext.Provider value={this.state.contextValue}>
      <Provider id="spectrum-wrapper" theme={defaultTheme} colorScheme={this.state.contextValue.scheme}>
        <View id="content-bg">
          <Navbar 
            onColorSchemeChange={this.onModeChange} 
            onTemplateSelect={this.onTemplateSelect}
            onTemplatesChanged={this.onTemplatesChanged}
            selected={this.state.selected} 
            templates={this.state.templates}/>
          <Flex direction="row" top="50px">
            <Sidebar selected={this.state.selected} onChange={this.onTemplatesChanged}/>
            <View backgroundColor={this.state.contextValue.scheme === 'light' ? 'gray-300' : 'static-black' } flex="1" id="content">
              <Renders template={this.state.selected}/>
            </View>
          </Flex>
        </View>
      </Provider>
    </AppContext.Provider>
  }
}
