import React from 'react';
import { Session, SessionEvents } from '@patterns/session';
import { User } from './entities';
import Axios from 'axios';
import { MainPage } from './pages/main.page';
import { LoginPage } from './pages/login.page';

import '@spectrum-css/typography';
import '@spectrum-css/vars/dist/spectrum-global.css'
import '@spectrum-css/vars/dist/spectrum-medium.css'
import '@spectrum-css/vars/dist/spectrum-light.css'
import '@spectrum-css/vars/dist/components/index.css'
import './App.css';

Axios.defaults.baseURL = '/api';

console.log('axios set')
export const session = new Session<User>(User, Axios, '/auth/login', '/auth/me')

export interface Props {}

export interface State {
  loggedIn: boolean
}

export default class App extends React.Component<Props, State> {
  state = {
    loggedIn: session.loggedIn
  }

  componentDidMount() {
    session.observeAll(this.onSessionEvent);
    session.restore()
  }

  private onSessionEvent = (event: SessionEvents) => this.setState({
    loggedIn: session.loggedIn
  })

  public render() {
    if (session.loggedIn) {
      return <MainPage session={session}/>
    }

    return <LoginPage session={session}/>
  }
}

