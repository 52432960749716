import { APIEntity } from "./api.entity";
import { Resource, deserializeString, deserializeNumber } from "@patterns/core";
import { Company } from "./company.entity";

export enum TemplateFormat {
  odt = 'odt',
  ods = 'ods',
  xlsx = 'xlsx'
}

@Resource('/templates')
export class Template extends APIEntity {
  code: string
  format: TemplateFormat
  name: string
  file?: string
  original?: string
  company: Company
  counter: number
  regenerations: number

  constructor(data: any = {}) {
    super(data);
    this.code = deserializeString(data.code);
    this.format = deserializeString(data.format, 'odt') as TemplateFormat;
    this.name = deserializeString(data.name);
    this.file = data.file;
    this.original = data.original;
    this.counter = deserializeNumber(data.counter, 0);
    this.regenerations = deserializeNumber(data.regenerations, 0);
    this.company = new Company(data.company || {});
  }
}
