import * as React from 'react';
import { Button, ButtonGroup, Content, Dialog, DialogTrigger, Divider, Flex, Form, Heading, StatusLight, Switch, Text, TextField } from '@adobe/react-spectrum';
import { Application } from '../entities';
import { ApplicationRepository } from '../repositories/application.repository';
import ApplicationDelivery from '@spectrum-icons/workflow/ApplicationDelivery';
import { AdobeBody } from './adobe/body';
import TokenPopover from './token.popover';

export interface Props {
  afterCreate: () => void
}

export function ApplicationsDialog({ afterCreate }: Props) {
  const [ name, setName ] = React.useState('');
  const [ showDialog, setShowDialog ] = React.useState(false);
  const [ applications, setApplications ] = React.useState([] as Application[]);
  const [ isActive, setIsActive ] = React.useState(true);

  const fetch = async () => {
    const [ items ] = await ApplicationRepository.index(0, 100, null);
    setApplications(items as Application[])
  }

  React.useEffect(() => {
    setName('');
  }, [ showDialog ]);

  React.useEffect(() => {
    fetch()
  }, []);

  const save = async () => {
    const application = new Application({
      name
    });
    await ApplicationRepository.save(application);
    setShowDialog(false);
    fetch();
    afterCreate()
  }

  return (
    <DialogTrigger isOpen={showDialog} onOpenChange={setShowDialog}>
      <Button variant="primary" marginStart="24px" isQuiet={true}>
        <ApplicationDelivery />
        <Text>Applications</Text>
      </Button>
      
      {(close) => (
        <Dialog size="S">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <ApplicationDelivery size="S" />
              <Text>Applications</Text>
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <Flex direction="column">
              <AdobeBody size="M">Existing applications</AdobeBody>
              <div>
              { applications.map(application => <Flex direction="row" alignItems="center" key={`application-item-${application.id}`}>
                <AdobeBody size="S">{ application.name }</AdobeBody>
                <Flex flex={1} direction="row" justifyContent="end">
                  <StatusLight marginTop="5px" variant="positive">Active</StatusLight>
                  <TokenPopover application={application}/>
                </Flex>
              </Flex>) }
              </div>
            </Flex>
            <Divider size="S" marginTop="12px" marginBottom="12px"/>
            <AdobeBody size="M">New application</AdobeBody>
            <Form>
              <TextField
                label="Name"
                placeholder="Enter application name"
                value={name}
                autoFocus
                onChange={setName}
              />
              <Switch isSelected={isActive} onChange={setIsActive}>Is Active</Switch>
            </Form>
          </Content>
          <ButtonGroup>
            <Button variant="secondary" onPress={close}>
              Cancel
            </Button>
            <Button variant="cta" onPress={save}>
              Create
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
    </DialogTrigger>
  );
}
