import { Content, Dialog, DialogTrigger, Divider, Flex, Heading, Text } from '@adobe/react-spectrum';
import * as React from 'react';
import { Render } from '../entities';

export interface Props {
  render: Render
  show: boolean
  onClose: () => void
}

export function DataViewDialog({ onClose, render, show }: Props) {  
  return (
    <DialogTrigger isOpen={show} onOpenChange={onClose}>
      <span/>
      {(close) => (
        <Dialog size="L">
          <Heading>
            <Flex alignItems="center" gap="size-100">
              <Text>Render Data</Text>
            </Flex>
          </Heading>
          <Divider />
          <Content>
            <pre>{ JSON.stringify(render.data, null, 2) }</pre>
          </Content>
        </Dialog>
      )}
    </DialogTrigger>
  );
}
