import * as React from 'react';
import { IllustratedMessage, Heading, Content } from '@adobe/react-spectrum';
import NotFound from '@spectrum-icons/illustrations/NotFound';

export interface Props {
}

export function NoTemplateSelected(props: Props) {
  return (
    <IllustratedMessage>
      <NotFound/>
      <Heading>No template selected</Heading>
      <Content>You can select or create one up at the top bar</Content>
    </IllustratedMessage>
  );
}
