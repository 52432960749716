import { Resource, deserializeString, BaseModel } from "@patterns/core";
import { Company } from "./company.entity";

export enum Role {
  User = 'user',
  Manager = 'manager',
  Administrator = 'administrator'
}

@Resource('/users')
export class User extends BaseModel {
  code: string
  name: string
  email: string
  password?: string
  role: Role
  company: Company

  constructor(data: any = {}) {
    super(data);
    this.code = deserializeString(data.code);
    this.name = deserializeString(data.name);
    this.email = deserializeString(data.email);
    this.role = deserializeString(data.role, 'user') as Role;
    this.company = new Company(data.company || {});
  }
}
