import { APIEntity } from "./api.entity";
import { Resource, deserializeString } from "@patterns/core";
import { Company } from "./company.entity";

@Resource('/applications')
export class Application extends APIEntity {
  name: string
  token: string
  isActive: boolean
  company: Company

  constructor(data: any = {}) {
    super(data);
    this.name = deserializeString(data.name);
    this.token = deserializeString(data.token);
    this.isActive = typeof data.isActive === 'undefined' ? true : data.isActive;
    this.company = new Company(data.company || {});
  }
}
