import { Session } from "@patterns/session"
import Axios from "axios"
import React from "react"
import { User } from "./entities"
import { ColorScheme } from "./pages/main.page"

export type AppContextValue = {
  scheme: ColorScheme
  session: Session<User>
}

export const AppContext = React.createContext<AppContextValue>({
  scheme: 'light',
  session: Session.ofType<User>(User, Axios)
})
