import { Application } from "../entities";
import { session } from '../App';

export class ApplicationRepository {
  static async index(page: number = 0, perPage: number = 100, cancelToken: any) {
    const response = await session.axios.get(`/applications`);
    const items = response.data.items.map((item: any) => new Application(item)) as Application[];
    const total = response.data.count as number;
    return [ items, total ]
  }

  static async save(application: Application) {
   if (application.exists) {
    await session.axios.put(`/applications/${application.id}`, application)
   } else {
    await session.axios.post('/applications', application)
   }

   return
  }
}