import { ActionGroup, Button, Content, Flex, Heading, IllustratedMessage, Item, StatusLight, Text, View } from '@adobe/react-spectrum';
import { deepEqual, formatDateTime } from '@patterns/core';
import Document from '@spectrum-icons/workflow/Document';
import Magnify from '@spectrum-icons/workflow/Magnify';
import Refresh from '@spectrum-icons/workflow/Refresh';
import NotFound from '@spectrum-icons/illustrations/NotFound';

import * as React from 'react';
import { AppContext } from '../app_context';
import { Render, Template } from '../entities';
import { RenderRepository } from '../repositories/render.repository';
import { AdobeBody } from './adobe/body';
import { DataViewDialog } from './data-view.dialog';
import { NoTemplateSelected } from './no_template';

export interface Props {
  template: Template
}

export interface State {
  isLoading: boolean
  renders: Render[]
  selected: Render
  total: number
}

export class Renders extends React.Component<Props, State> {
  static contextType = AppContext;

  cancelToken: any

  state = {
    isLoading: false,
    renders: [] as Render[],
    selected: new Render({}),
    total: 0
  }
  
  componentDidMount() {
    if (this.props.template.exists) {
      this.fetch()
    }
  }

  componentDidUpdate(prevProps: Props) {
    if (!deepEqual(this.props.template, prevProps.template)) {
      this.fetch()
    }
  }

  private fetch = async () => {
    this.setState({
      isLoading: true
    }, async () => {
      const [ items, count ] = await RenderRepository.index(0, 100, this.props.template, this.cancelToken);
      this.setState({ 
        isLoading: false, 
        renders: items as Render[],
        total: count as number
      })
    })
  }

  private viewData = (render: Render) => this.setState({ selected: render })

  private preview = async (render: Render) => {
    const url = `/api/renders/${render.code}`;
    this.context.session.download(url)
  }

  private onDataDialogClose = () => this.setState({ selected: new Render({}) })

  private renderItems = () => {
    return this.state.renders.map((render, index) => <View 
      key={`render-${render.id}`} 
      backgroundColor={ index % 2 ? 'gray-100' : 'gray-50' } >
      <Flex direction="row" height="48px" alignItems="center">
        <View flex={1} paddingStart="24px">
          <AdobeBody size="S">{ formatDateTime(render.createdAt) }</AdobeBody>
        </View>
        <View flex={1}>
          <div className="spectrum-Tags" role="list" aria-label="list">
            <div className="spectrum-Tags-item" role="listitem">
              <span className="spectrum-Tags-itemLabel">{ render.returnType }</span>
            </div>
          </div>
        </View>
        <View flex={1}>
          { render.regeneration ? <Refresh /> : <span/> }
        </View>
        <View flex={1}>
          <AdobeBody size="S">{ render.templateVersion }</AdobeBody>
        </View>
        <View flex={1}>
          <AdobeBody size="S"></AdobeBody>
        </View>
        <View flex={2}>
          <Button variant="secondary" isQuiet={true} onPress={() => this.preview(render)}>
            <Document />
            <Text>Preview</Text>
          </Button>
          <Button variant="secondary" isQuiet={true} onPress={() => this.viewData(render)}>
            <Magnify />
            <Text>Data</Text>
          </Button>
        </View>
      </Flex>
    </View>)
  }

  public render() {
    if (!this.props.template.exists) {
      return <NoTemplateSelected />
    }

    return (
      <Flex direction="column" height="100%">
        <Flex direction="row" height="80px">
          <View flex={1} marginStart="24px" marginBottom="24px">
            <Heading>
              <Flex direction="row" alignItems="center">
                <span>{ this.props.template.name } Template Renders</span>
                <StatusLight variant="positive">Active</StatusLight>
              </Flex>
            </Heading>
          </View>
          <View flex={1} marginTop="24px" marginBottom="24px" marginEnd="24px">
            <Flex alignItems="start" justifyContent="end">
              <ActionGroup>
                <Item key="add">Delete all Template Renders</Item>
                <Item key="delete">Download Report</Item>
                <Item key="edit">Disable Template</Item>
              </ActionGroup>
            </Flex>
          </View>
        </Flex>

        { this.state.renders.length > 0 ?
          <Flex direction="column" flex={1}>
            <Flex direction="row">
              <View flex={1} paddingStart="24px" paddingBottom="12px">
                <AdobeBody strong={true} size="S">Date</AdobeBody>
              </View>
              <View flex={1}>
                <AdobeBody strong={true} size="S">Return Type</AdobeBody>
              </View>
              <View flex={1}>
                <AdobeBody strong={true} size="S">Regeneration</AdobeBody>
              </View>
              <View flex={1}>
                <AdobeBody strong={true} size="S">Template Version</AdobeBody>
              </View>
              <View flex={1}>
                <AdobeBody strong={true} size="S">Application</AdobeBody>
              </View>
              <View flex={2}>
                <AdobeBody strong={true} size="S">Actions</AdobeBody>
              </View>
            </Flex>

            <Flex direction="column" marginTop="12px">{ this.renderItems() }</Flex>
          </Flex> :
          <Flex direction="column" flex={1} alignItems="center" justifyContent="center">
            <IllustratedMessage>
              <NotFound />
              <Heading>No renders available yet</Heading>
              <Content>Renders will appear here as soon as they are requested</Content>
            </IllustratedMessage> 
          </Flex>
        }


        <DataViewDialog 
          render={this.state.selected} 
          show={this.state.selected.exists} 
          onClose={this.onDataDialogClose}/>
      </Flex>
    );
  }
}
