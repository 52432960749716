import * as React from 'react';

export interface Props {
  size: 'S' | 'M' | 'L' | 'XL' | 'XXL' | 'XXXL'
  em?: boolean
  strong?: boolean
}

export function AdobeBody ({ children, em, size, strong }: React.PropsWithChildren<Props>) {
  const className=`spectrum-Body spectrum-Body--${size}`;

  if (strong) {
    return <p className={className}>
      <strong>{ children }</strong>
    </p>
  }

  if (em) {
    return <p className={className}>
      <em>{ children }</em>
    </p>
  }

  return <p className={className}>{ children }</p>;
}
